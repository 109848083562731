import React, { useEffect } from 'react'
import { PageProperties } from '../types'
import { getSentenceWithLinkPieces } from '../utils'

const Widget = ({ page }: PageProperties) => {
  useEffect(() => {
    if (page.content.widget?.scriptSource) {
      const head = document.querySelector('head')
      const script = document.createElement('script')
      script.setAttribute('src', page.content.widget.scriptSource)
      head?.appendChild(script)
    }
  }, [page.content.widget])
  const { sentenceWithLink } = page.content
  const [sentenceWithLinkPartOne, linkText, sentenceWithLinkPartTwo] = sentenceWithLink ? getSentenceWithLinkPieces(sentenceWithLink) : []

  return (
    <div className="view force-full-view"
      style={{
        height: '100%',
        background: `linear-gradient(${page.gradient?.direction}, ${page.gradient?.first}, ${page.gradient?.second})`
      }}>
      <div className="title forceWhite">
        {page.title}
      </div>
      <div className="subtitle forceWhite">
        {page.subtitle}
      </div>
      <hr />
      <span className="is-size-6">
        {sentenceWithLinkPartOne}
        <a
          className="forceWhite is-italic underline"
          rel="noopener noreferrer"
          href={sentenceWithLink?.link}
        >
          {linkText}
        </a>
        {sentenceWithLinkPartTwo}
      </span>
      <div
        {...page.content.widget?.divProps}
      />
    </div>
  )
}

export default Widget