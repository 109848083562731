import React, { useMemo, useState } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from 'react-router-dom'

import Landing from './views/Landing'
import ItemDetail from './views/ItemDetail'
import ItemList from './views/ItemList'
import ContactForm from './views/ContactForm'
import Widget from './views/Widget'
import TextOnly from './views/TextOnly'

import './App.css'
import { Page, WebSite } from './types'

const App = ({ website }: { website: WebSite }) => {
  const menuOptions = website.pageOrder.map((route: string) => (
    { path: `/${route !== website.config.defaultRoute ? route : ''}`, name: route }
  ))

  const [burguerMenuActive, setBurguerMenuActive] = useState(false)
  const [activeMenu, setActiveMenu] = useState(website.config.defaultRoute)

  const location = useMemo(() => window.location, [window.location])
  const currentPath = useMemo(() => location.pathname, [location.pathname])
  const currentPage = useMemo(() => website.pages[Object.keys(website.pages).find(k => currentPath.endsWith(k))! || 'home'], [currentPath, website.pages])

  const toggleBurguerMenu = (skipIfNotOpen = false) => {
    if (skipIfNotOpen && burguerMenuActive === false) {
      return
    }
    setBurguerMenuActive(!burguerMenuActive)
  }

  const handleMenuNav = (name: string) => {
    toggleBurguerMenu(true)
    if (activeMenu !== name) {
      setActiveMenu(name)
    }
  }

  const getElementForRoute = (page: Page) => {
    switch (page.pageType) {
      case 'landing-social': {
        return <Landing page={page} />
      }
      case 'item-detail': {
        return <ItemDetail page={page} />
      }
      case 'item-list': {
        return <ItemList page={page} />
      }
      case 'text-only': {
        return <TextOnly page={page} />
      }
      case 'form-contact': {
        return <ContactForm page={page} />
      }
      case 'widget': {
        return <Widget page={page} />
      }
    }
  }

  return (
    <BrowserRouter>
      <div className="App">
        <nav className="navbar" aria-label="main-navigation">
          <div className="navbar-brand">
            {website.config.logo.brandLogoUri
              ? <img className="navbar-item logo" src={require(`./site/images/${website.config.logo.brandLogoUri}`)} alt={website.config.logo.name} width="800" />
              : <h1 className="forceBlack">{website.config.logo.name}</h1>
            }
            <div
              className={`navbar-burger burguer inline ${burguerMenuActive ? 'is-active' : 'inactive'}`}
              aria-label="menu"
              aria-expanded="false"
              onClick={() => toggleBurguerMenu()}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </div>
          </div>
          <div className={burguerMenuActive ? 'navbar-menu is-active' : 'navbar-menu'}>
            <div className="navbar-end">
              {
                menuOptions.map(option => (
                  <Link className="navbar-item"
                    key={option.path}
                    onClick={() => handleMenuNav(option.name)}
                    to={option.path}>
                    <div style={{ fontWeight: 600, color: currentPage?.menuColor ?? 'inherit' }}>
                      <span className={(option.path === '/' && currentPath === 'home') || option.path.endsWith(currentPath) ? 'is-uppercase' : ''}>
                        {option.name}
                      </span>
                    </div>
                  </Link>))
              }
            </div>
          </div>
        </nav>
        <Routes>
          {
            Object.keys(website.pages).map((key) => (
              <Route key={key} path={key === 'home' ? "/" : `/${key}/`} element={getElementForRoute(website.pages[key])} />
            ))
          }
        </Routes>
      </div>
    </BrowserRouter >
  )
}

export default App