import { Page, WebSite, WebSiteConfig } from '../types';

const pages: Record<string, Page> = {
  home: {
    pageType: "landing-social",
    menuColor: "#00aeef",
    title: "Front End Engineer",
    subtitle: "I specialize in React & React Native development to craft simple and beautiful solutions for Web, iOS and Android.",
    content: {
      quote: {
        text: "In all worthwhile projects - Personal or Professional, Tech or Non-tech; I become passionate about the Big picture, Continuous improvement, and Iterative processes.",
        author: "Fabio Valentino"
      },
      iconList: [
        { name: 'fab fa-medium-m', link: 'http://www.medium.com/@fabiovalentino_' },
        { name: 'fab fa-gitlab', link: 'http://www.gitlab.com/lagosoft' },
        { name: 'fab fa-github', link: 'http://www.github.com/lagofvc' },
        { name: 'fab fa-linkedin', link: 'http://www.linkedin.com/in/fabiovalentino' },
        { name: 'fab fa-meetup', link: 'http://www.meetup.com/Latinx-Tech-PDX' },
      ]
    },
    gradient: {
      direction: "to right",
      first: "#0a9fe0",
      second: "#6251a2"
    }
  },
  resume: {
    pageType: "item-detail",
    menuColor: "#1c6bab",
    title: "Web & Moblie App Development",
    subtitle: "Portland, Oregon",
    content: {
      pageImageLeft: {
        uri: "resume-screenshot.png",
        legend: "(click to download)",
        link: "resume.pdf"
      },
      parragraph: "I specialize on universal application development (web, mobile, desktop) using React & React-Native.    As a 7 on the Enneagram I've learned to combine my expertise and interests in order to provide deep value to my Clients and Teams.",
      textList: [
        "Technical problem-solving", "Front End development", "User Experience", "Product Development", "Style & visual congruency", "TDD & Agile Processes"
      ],
      sentenceWithLink: {
        text: "If you have a project that you would like to discuss, feel free to contact me, I would love to speak with you or your Team.",
        linkText: "contact me",
        link: "./contact"
      }
    },
    gradient: {
      direction: "to right",
      first: "#4273be",
      second: "#753f91"
    }
  },
  portfolio: {
    pageType: "item-list",
    menuColor: "#9b2d88",
    title: "Portfolio",
    subtitle: "Current & Recent Projects",
    content: {
      items: [
        {
          title: 'homeslice',
          image: {
            uri: 'iphone5s_gold_portrait.png',
            legend: '(click to open)',
            link: 'http://homeslicesite.s3-website-us-west-2.amazonaws.com',
          },
          description: `HomeSlice is a React-Native & Firebase application made for Roommates to more easily keep track of shared expenses and bills. Currently available on Expo (for iOS and Android).`,
        }, {
          title: 'blockchainTE',
          image: {
            uri: 'blockchaintechevents.png',
            legend: '(click to open)',
            link: 'http://blockchaintechevents.com',
          },
          description: `Blockchain Tech Events is a site for listing, curating and learning about relevant blockchain tech events.  Built with Angular, Firebase, Akveo's Nebular`,
        }, {
          title: 'oleada',
          image: {
            uri: 'oleadapdx.png',
            legend: '(click to open)',
            link: 'http://oleadapdx.com.s3-website-us-west-2.amazonaws.com',
          },
          description: 'A simple page for my band, Oleada, using WebAudio and Angular.',
        }, {
          title: 'ethExplorer',
          image: {
            uri: 'eth_block_explorer.png',
            legend: '(click to open)',
            link: 'https://nostalgic-goldberg-31f88e.netlify.app',
          },
          description: `As a way to learn about the Ethereum blockchain, I created this interface to explore the last 10 blocks and their transaction details on the main Eth Net using ReactJS and the api.blockcypher.com API.`,
        }, {
          title: 'metronix',
          image: {
            uri: 'metronix2.png',
            legend: '(click to open)',
            link: 'http://metronix-www.s3-website-us-west-2.amazonaws.com',
            width: 300,
          },
          description: `Metronix is a metronome app for musicians.  It allows the user to set a sequence of practice intervals, rests, with varying meters, timers and bpm's so as to minimize the time spent configuring an entire practice.`,
        },
      ]
    },
    gradient: {
      direction: 'to right',
      first: '#544898',
      second: '#9b2d88'
    }
  },
  contact: {
    pageType: "form-contact",
    menuColor: "#ef2a7a",
    title: "Let's Connect!",
    subtitle: "I'd love to hear about your project",
    content: {
      form: 'CONTACT'
    },
    gradient: {
      direction: 'to right',
      first: '#862d8b',
      second: '#cc2c7f'
    }
  },
  mentor: {
    pageType: "text-only",
    menuColor: "#1c6bab",
    title: "✊🏼 ✊🏽 ✊🏾 ✊🏿 POC in Tech Mentor✊🏿 ✊🏾 ✊🏽 ✊🏼",
    subtitle: "",
    content: {
      parragraph: `
        In an effort to help diversify Oregon's Tech space, I am offering mentorship sessions to local(aspiring or working) developers looking to grow their careers

        If you are interested and identify with any of the following backgrounds: Black / African, Native / Indigenous, Latin American, Arabic, Southeast Asian or Pacific Islander, please fill out the form linked below.  I'll be happy to share insight from my years in the industry as a dev in different company teams and independently as a consultant.
      `,
      sentenceWithLink: {
        text: 'Get in touch here.',
        linkText: 'here',
        link: 'https://bit.ly/32xlThH'
      }
    },
    gradient: {
      direction: 'to right',
      first: '#4273be',
      second: '#753f91'
    }
  },
  schedule: {
    pageType: "widget",
    menuColor: "#00aeef",
    title: "Book a time for our chat",
    subtitle: "Use my open calendar below to schedule a time",
    content: {
      sentenceWithLink: {
        text: "If nothing that is open works for you, please reach out with a meassage.",
        linkText: "please reach out",
        link: "./contact",
      },
      widget: {
        scriptSource: 'https://assets.calendly.com/assets/external/widget.js',
        divProps: {
          id: "schedule_form",
          className: "calendly-inline-widget",
          "data-url": "https://calendly.com/fabiovalentino",
          style: { minWidth: '80vw', height: '80vh', backgroundColor: 'transparent' },
        }
      }
    },
    gradient: {
      direction: "to right",
      first: "#0a9fe0",
      second: "#6251a2"
    }
  }
}

const config: WebSiteConfig = {
  browserTabTitle: "Fabio Valentino",
  logo: {
    name: "Fabio Valentino",
    brandLogoUri: "fabiovalentino_orig.svg"
  },
  text: {
    color: "#fff",
    fonts: {
      primary: {
        family: "Exo",
        url: "https://fonts.googleapis.com/css?family=Exo&display=swap"
      }
    }
  },
  domain: "fabiovalentino.com",
  facebook: {
    pixel: {
      id: "210715353043125",
      imageSrc: "https://www.facebook.com/tr?id=210715353043125&ev=PageView&noscript=1"
    },
    metadata: {
      "og:title": {
        attribute: "content",
        value: "fabiovalentino.com"
      },
      "og:description": {
        attribute: "content",
        value: "Fabio Valentino indy software developer"
      },
      "og:type": {
        attribute: "content",
        value: "website"
      },
      "og:url": {
        attribute: "content",
        value: "https://fabiovalentino.com/"
      },
      "og:image": {
        attribute: "content",
        value: "https://fabiovalentino.com/thumbnail.png"
      },
      "fb:app_id": {
        attribute: "content",
        value: "685048371848508"
      }
    }
  },
  defaultRoute: "home",
}

const website: WebSite = {
  config,
  pages,
  pageOrder: ['home', 'resume', 'portfolio', 'contact'],
}

export default website

