import React from 'react'

import './ItemList.css'
import { ItemDetail, PageProperties } from '../types'

const getItemList = (items: Array<ItemDetail>) => (
  <div className="thin">
    {
      items?.map((proj, i) => {
        const imageDiv = (<div className="project-img has-text-centered  is-flex-direction-column is-align-items-center is-align-self-center">
          <div className="is-size-7 is-align-items-center is-align-self-center">{proj.image?.legend}</div>
          <img
            className="project-img-file"
            width={proj.image?.width ? proj.image.width : "600"}
            alt={proj.title}
            src={require(`../site/images/${proj.image?.uri}`)}
            onClick={() => window.open(proj.image?.link)}
            onKeyPress={() => window.open(proj.image?.link)}
          />
        </div>)

        const descriptionDiv = (<div className="project-desc">
          <div className="forceWhite is-size-4 is-italic">
            {proj.description}
          </div>
        </div>)

        return (i % 2 === 0
          ? (
            <div className="project" key={proj.title}>
              {imageDiv}
              {descriptionDiv}
              <hr className="bigHR" />
            </div>
          )
          : (
            <div className="project" key={proj.title}>
              {descriptionDiv}
              {imageDiv}
            </div>
          ))
      })
    }
  </div>
)

const ItemList = ({ page }: PageProperties) => {
  return (
    <div className="view force-full-view"
      style={{
        height: '100%', padding: '6%',
        background: `linear-gradient(${page.gradient?.direction}, ${page.gradient?.first}, ${page.gradient?.second})`
      }}>
      <div className="title forceWhite">
        {page.title}
      </div>
      <div className="subtitle forceWhite">
        {page.subtitle}
      </div>
      <hr />
      {page.content.items && getItemList(page.content.items)}
    </div>
  )
}

export default ItemList