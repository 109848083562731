import React from 'react'
import { PageProperties } from '../types'
import './Landing.css'

const Landing = ({ page }: PageProperties) => {
  return (
    <div
      className="view force-full-view"
      style={
        { padding: '10%', paddingTop: '12%', background: `linear-gradient(${page.gradient?.direction}, ${page.gradient?.first}, ${page.gradient?.second})` }
      }
    >
      <div className="title forceWhite">
        {page.title}
      </div>
      <div className="subtitle forceWhite">
        {page.subtitle}
      </div>
      <hr />
      <div style={{ paddingLeft: 10, paddingRight: 10 }}>
        <div className="is-size-5">
          {page.content.quote?.text}
          <div className="is-italic has-text-right">{`- ${page.content.quote?.author}`}</div>
        </div>
        <div className="logo-row">
          {
            page.content.iconList?.map((icon) => (
              <div
                className="fv-social-icon"
                key={icon.name}
                onClick={() => window.open(icon.link)}
              >
                <i className={icon.name} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Landing;