import axios from 'axios'

const DROP_DOWN_TYPE = 'drop_down'

// TODO: Extract into SECRETS
const CLICKUP_PAT = 'pk_48111182_86MR52DECIOKIS3HOOL63OL504S7XG7W'
const BASE_API_URL = 'https://api.clickup.com'
export const CONTACT_FORM_SUBMISSION_LIST_ID = '901102696661'

const commonHeaders = {
    Authorization: CLICKUP_PAT,
    'Access-Control-Allow-Origin': 'true',
}
const reqHeaders = ({
    headers: commonHeaders,
})
const postReqHeaders = ({
    headers: {
        ...commonHeaders,
        'Content-Type': 'application/json'
    }
})

export const convertObjToClickUpTask = (obj: any, fieldMap: any) => {
    let custom_fields: any[] = []
    const customKeys = Object.keys(fieldMap);
    customKeys.forEach((key: string) => custom_fields.push({ ...fieldMap[key], value: obj[key] }))
    return ({
        name: obj.name,
        custom_fields
    })
}

export const saveItemInList = async (obj: any, listId: string, listFieldMap: any) => {
    try {
        const requestUrl = `${BASE_API_URL}/api/v2/list/${listId}/task`
        const result: any = await axios.post(requestUrl, obj, postReqHeaders)
        const customResponse = await getCustomFieldResponse(listFieldMap, result.data)
        return customResponse
    } catch (error: any) {
        console.log(error)
        console.error(`ERROR in saveItemInList for ${listId}`, error.toString())
    }
}

const getCustomFieldResponse = (listFieldMap: any, item: any): any => {
    if (!listFieldMap || Object.keys(listFieldMap).length == 0 || !item || !item.custom_fields) {
        return undefined
    }
    const response: any = {}
    Object.keys(listFieldMap).forEach((k: string) =>
        response[k] = item.custom_fields.find((field: any) => field.name === k).value
    )
    return { ...response, id: item.id }
}

export const getFieldMapForList = async (listId: string) => {
    try {
        let fieldMap = Object.assign({})
        const requestUrl = `${BASE_API_URL}/api/v2/list/${listId}/field`
        const response: any = await axios.get(requestUrl, reqHeaders)

        if (response && response.data && response.data.fields) {
            fieldMap = response.data.fields.reduce((acc: any[], current: any) => {
                const hasOptions = current.type === DROP_DOWN_TYPE
                acc[current.name] = { id: current.id }
                if (hasOptions) {
                    acc[current.name].options = current.type_config.options
                }
                return acc
            }, {})
        }
        return fieldMap
    } catch (error: any) {
        console.error('ERROR in getFieldMapForList ', error)
        console.log(error)
    }
}

// export const PARKING_SECTIONS_LIST_NAME = 'Parking Sections'
// export const PARKING_SPACE_LIST_NAME = 'Parking Spaces'
// export const CUSTOMERS_LIST_NAME = 'Customers'
// export const VEHICLES_LIST_NAME = 'Vehicles'
// export const SPACE_VEHICLES_LIST_NAME = 'Space Vehicles'
// const OPTION_VALUE_KEY = 'orderindex'

// export type DbDomain =
//     typeof PARKING_SPACE_LIST_NAME | typeof PARKING_SECTIONS_LIST_NAME |
//     typeof CUSTOMERS_LIST_NAME | typeof VEHICLES_LIST_NAME | typeof SPACE_VEHICLES_LIST_NAME

// const isOfDomainType = (value: string): value is DbDomain =>
//     [PARKING_SPACE_LIST_NAME, PARKING_SECTIONS_LIST_NAME, CUSTOMERS_LIST_NAME, VEHICLES_LIST_NAME, SPACE_VEHICLES_LIST_NAME].includes(value)



// export const getListIdMap = async () => {
//     const requestUrl = `${BASE_API_URL}/api/v2/folder/${FOLDER_ID}/list`
//     try {
//         let listMapForFolder = {}
//         const response: any = await axios.get(requestUrl, reqHeaders)
//         if (response && response.data && response.data.lists) {
//             listMapForFolder = response.data.lists
//                 .filter((item: any) => item && !item.archived && isOfDomainType(item.name))
//                 .reduce((acc: any[], current: any) => {
//                     acc[current.name] = current.id
//                     return acc
//                 }, {})
//         }
//         return listMapForFolder
//     } catch (error: any) {
//         console.error('ERROR in getListIdMap : ', error)
//         console.log(error)
//     }
// }




// export const getItemsInListFor = (listIdMap: any, tableName: string) => {
//     const specificListId = listIdMap[tableName]
//     return getItemsInList(specificListId)
// }

// export const getItemsInList = async (domainId: string) => {
//     try {
//         const requestUrl = `${BASE_API_URL}/api/v2/list/${domainId}/task`
//         const response = await axios.get(requestUrl, reqHeaders)

//         if (response && response.data && response.data.tasks) {
//             const items = response.data.tasks
//             const listFieldMap = await getFieldMapForDomain(domainId)
//             const trimmedResponse = items.map((item: any) => {

//                 const finalRes = getCustomFieldResponse(listFieldMap, item)
//                 return {
//                     ...finalRes,
//                     originalId: item.id,
//                     originalName: item.name,
//                     // set item.id as originalId which is ClickUps item id used in URLs but we wont use it ourelve besides for correlation

//                 }
//             })
//             return trimmedResponse
//         }
//         else {
//             throw 'Unable to find response.data.tasks'
//         }
//     } catch (error: any) {
//         console.log(`ERROR in getItemsInList for ${domainId}`)
//         console.log(error)
//     }
// }

// TODO Make string or number work
// export const getValueForOptionInField = (tableFieldMap: any, fieldName: string, valueToMatch: string) => {
//     const returnVal = tableFieldMap[fieldName.toLowerCase()].options
//         .find((o: any) => {
//             return o.name.toLowerCase() === valueToMatch.toLowerCase()
//         })[OPTION_VALUE_KEY]
//     return returnVal
// }

// export const getTextForOptionInFieldValue = (tableFieldMap: any, fieldName: string, valueToMatch: string | number) => {
//     const isValueString = typeof valueToMatch === 'string'
//     const returnVal = tableFieldMap[fieldName.toLowerCase()].options
//         .find((o: any) => {
//             if (isValueString) {
//                 return o.name.toLowerCase() === valueToMatch.toLowerCase()
//             } else {
//                 return o.orderindex === valueToMatch
//             }
//         })[isValueString ? OPTION_VALUE_KEY : 'name']
//     return returnVal
// }

// export const resetSpaceToDefaults = async (
//     space: any, sections: any[],
//     parkingSpacesListId: string,
//     tableFieldMap: any
// ): Promise<any> => {
//     const numberFromSpaceNumber: number = space.spaceNumber.split('-')[1]
//     const matchingSection = sections.find((section: any) =>
//         numberFromSpaceNumber >= Number(section.start) &&
//         numberFromSpaceNumber <= Number(section.end)
//     )
//     if (!matchingSection) {
//         throw 'No matching section found!'
//     }

//     const { width, length, cost } = matchingSection
//     const objToSave = {
//         name: space.spaceNumber,
//         custom_fields: [
//             {
//                 id: tableFieldMap.spaceNumber.id,
//                 value: space.spaceNumber
//             },
//             {
//                 id: tableFieldMap.status.id,
//                 // TODO: get this status from parking sections instead for initializing a section
//                 value: getValueForOptionInField(tableFieldMap, 'status', 'Vacant')
//             },
//             {
//                 id: tableFieldMap.cost.id,
//                 value: cost
//             },
//             {
//                 id: tableFieldMap.width.id,
//                 value: width
//             },
//             {
//                 id: tableFieldMap.length.id,
//                 value: length
//             }
//         ]
//     }
//     return await saveItemInList(objToSave, parkingSpacesListId, tableFieldMap)
// }